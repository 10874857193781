@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,700,800&display=swap");
@font-face {
  font-family: "FontAwesome";
  src: url(../fonts/fontawesome-webfont.ttf) format("truetype");
}

@font-face {
  font-family: "BigNoodle";
  src: url("../fonts/big_noodle/big_noodle_titling.eot?#iefix") format("embedded-opentype"), url("../fonts/big_noodle/big_noodle_titling.otf") format("opentype"), url("../fonts/big_noodle/big_noodle_titling.woff") format("woff"), url("../fonts/big_noodle/big_noodle_titling.ttf") format("truetype"), url("../fonts/big_noodle/big_noodle_titling.svg#AvenirLTStd") format("svg");
  font-weight: 400;
  font-style: normal;
}

html, body {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  color: #333;
  min-height: 100%;
}

section {
  display: block;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "BigNoodle";
  padding-bottom: 1rem;
  color: inherit;
  font-weight: 400;
  line-height: 1em;
  margin: 0;
  text-transform: uppercase;
}

h1 {
  font-size: 2.75rem;
}

h2 {
  font-size: 2.2rem;
}

h3 {
  text-transform: initial;
  font-size: 2rem;
}

h4 {
  font-size: 2.4rem;
}

h5 {
  font-size: 1.6rem;
}

a {
  color: inherit;
}

a:hover {
  text-decoration: none;
}

img {
  max-width: 100%;
}

p {
  margin: 0;
  padding-bottom: 1rem;
}

[v-cloak] {
  display: none;
}

.slick_carousel {
  overflow: hidden;
  position: relative;
}

.slick_carousel .slick_carousel_image {
  height: 100%;
  background: #fff;
}

.slick_carousel .slick_carousel_image img {
  max-height: 500px;
}

.slick_carousel .slick-slide img {
  display: inline;
}

.slick_carousel .slick_carousel_video {
  height: 500px;
}

.slick_carousel .slick_carousel_video iframe.video_frame {
  width: 100%;
  height: 100%;
}

.slick_carousel .slick-arrow {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: absolute;
  width: 5rem;
  height: 5rem;
  padding: 0;
  margin: 0;
  border: 0;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.5) !important;
  opacity: 1;
  line-height: 5rem;
  font-size: 2rem;
  text-align: center;
  text-shadow: none;
  color: #007bff;
  transition: all 300ms ease 0ms;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.075);
  z-index: 20;
}

.slick_carousel .slick-arrow.slick-arrow-left {
  left: .5rem;
}

.slick_carousel .slick-arrow.slick-arrow-right {
  right: .5rem;
}

.slick_carousel .slick-arrow:hover {
  background: white !important;
}

#main-container.main-container {
  margin-bottom: 28px;
}

#body {
  background-color: #fff;
  background-size: 100% auto;
  min-height: 100vh;
}

.am_button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
  padding: 0;
  border: 0;
  background-color: transparent;
  border-radius: 6px;
}

#page_container {
  width: 100%;
  padding: 0;
  max-width: 1425px;
  margin-left: auto;
  margin-right: auto;
}

#logo {
  text-align: center;
  margin-bottom: 2rem;
}

#logo img {
  width: 300px;
}

#header {
  text-align: center;
}

.btn-primary, .btn-primary.focus, .btn-primary:focus {
  background-color: #007bff !important;
  border-color: #007bff;
}

.btn-primary.focus:hover, .btn-primary:active:hover, .btn-primary:focus:active, .btn-primary:focus:hover, .btn-primary:hover, .open > .btn-primary.dropdown-toggle, .open > .btn-primary.dropdown-toggle.focus, .open > .btn-primary.dropdown-toggle:active, .open > .btn-primary.dropdown-toggle:focus, .open > .btn-primary.dropdown-toggle:hover {
  background-color: #007bff !important;
  border-color: #007bff;
}

#video_player {
  margin: 2rem 0;
}

#cdcsm_quiz_wrapper {
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 2rem;
}

.product_tab_template2 {
  margin: 1.5%;
  border-radius: 10px;
  overflow: hidden;
}

.product_tab_box {
  width: 100%;
}

.product_tab_box:not(.transparent) {
  background-color: #fff;
}

.product_tab_box .video_frame {
  width: 100%;
  height: 635px;
}

.tab_section_loader {
  padding: 3rem;
  text-align: center;
}

.tab_section_loader i {
  margin-right: 5px;
  vertical-align: middle;
}

.product_retail_book {
  width: 100%;
  height: 700px;
}

.stats_general {
  padding: 15px;
  background-color: #fff;
}

.stats_items_container {
  padding: 15px;
}

.stats_items_container .stats_item_box {
  background-color: #fff;
  margin-bottom: 15px;
  padding: 15px;
}

.stats_items_container .stats_item_box h5 {
  text-align: center;
}

.stats_items_container .stats_item_box .quiz_stats_box {
  margin-top: 15px;
  padding: 10px;
  background-color: #f7f7f7;
}

.stat_attributes .stat_attribute_row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;
}

.stat_attributes .stat_attribute_row:not(:last-child) {
  border-bottom: 1px solid #eee;
}

.stat_attributes .stat_attribute_row .stat_attribute_title {
  width: 50%;
  padding: 7px 15px;
  box-sizing: border-box;
}

.stat_attributes .stat_attribute_row .stat_attribute_value {
  width: 50%;
  padding: 7px 15px;
  box-sizing: border-box;
  font-weight: 800;
}

.stat_attributes .stat_attribute_row .stat_attribute_value > .stat_attribute_value_item:not(:first-child) {
  padding-top: 1rem;
}

.stat_attributes .stat_attribute_row .stat_attribute_value > .stat_attribute_value_item:not(:last-child) {
  padding-bottom: 1rem;
  border-bottom: 2px solid #eee;
}

.stat_attributes .stat_attribute_row .stat_attribute_value .stat_score_bar {
  position: relative;
  text-align: center;
  justify-content: center;
  background-color: #f7f7f7;
  font-size: .8rem;
}

.stat_attributes .stat_attribute_row .stat_attribute_value .stat_score_bar .stat_score_progress {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #007bff;
  height: 100%;
  padding-left: .3rem;
}

.stat_attributes .stat_attribute_row .stat_attribute_value .stat_score_bar .stat_execution_score_number {
  position: relative;
}

.quiz_stats_box .stat_score_bar {
  background-color: #fff !important;
}

.product_attribute_row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  border-top: 5px solid #f2f2f2;
}

.product_attribute_row .product_attribute_title {
  width: 50%;
  padding: 15px;
  box-sizing: border-box;
  background-color: #fff;
}

.product_attribute_row .product_attribute_value {
  width: 50%;
  padding: 15px;
  box-sizing: border-box;
  font-weight: 800;
  font-size: 110%;
  background-color: #f7f7f7;
}

.product_attribute_row .product_attribute_value > .product_attribute_value_item:not(:first-child) {
  padding-top: 1rem;
}

.product_attribute_row .product_attribute_value > .product_attribute_value_item:not(:last-child) {
  padding-bottom: 1rem;
  border-bottom: 2px solid #eee;
}

.product_attribute_row.dense .product_attribute_title, .product_attribute_row.dense .product_attribute_value {
  padding: 7px 15px;
}

.product_link_row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  border-top: 5px solid #f2f2f2;
}

.product_link_row .product_link_title {
  width: 50%;
  padding: 15px;
  box-sizing: border-box;
}

.product_link_row .product_link_value {
  width: 50%;
  padding: 15px;
  box-sizing: border-box;
  font-weight: 800;
  font-size: 110%;
  background-color: #f7f7f7;
}

.product_link_row .product_link_value > .product_attribute_value_item:not(:first-child) {
  padding-top: 1rem;
}

.product_link_row .product_link_value > .product_attribute_value_item:not(:last-child) {
  padding-bottom: 1rem;
  border-bottom: 2px solid #eee;
}

.product_link_row .product_attribute_value > .product_attribute_value_item:not(:first-child) {
  padding-top: 1rem;
}

.product_link_row .product_attribute_value > .product_attribute_value_item:not(:last-child) {
  padding-bottom: 1rem;
  border-bottom: 2px solid #eee;
}

.image_link_row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  border-top: 5px solid #f2f2f2;
}

.image_link_row .image_link_title {
  width: 50%;
  padding: 15px;
  box-sizing: border-box;
}

.image_link_row .image_link_value {
  width: 50%;
  padding: 15px;
  box-sizing: border-box;
  font-weight: 800;
  font-size: 110%;
  background-color: #f7f7f7;
}

.image_link_row .image_link_value > .product_attribute_value_item:not(:first-child) {
  padding-top: 1rem;
}

.image_link_row .image_link_value > .product_attribute_value_item:not(:last-child) {
  padding-bottom: 1rem;
  border-bottom: 2px solid #eee;
}

#quiz_video {
  height: 641px;
}

.quiz_presentation {
  width: 100%;
  max-width: 800px;
  padding: 1.5rem;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  text-align: left;
  color: #fff;
}

.quiz_presentation .quiz_presentation_image {
  width: 7rem;
  height: 7rem;
  margin-right: 1.5rem;
  border-radius: 3.5rem;
  background-color: #17a2b8;
  background-size: cover;
  background-position: center center;
  color: #fff;
  line-height: 7;
  text-align: center;
}

.quiz_presentation .quiz_presentation_text {
  width: calc(100% - 8.5rem);
  padding-top: 1rem;
}

.quiz_presentation .quiz_presentation_text .quiz_presentation_title {
  margin-bottom: .75rem;
  text-align: left !important;
  padding: 0 !important;
}

.quiz_presentation .quiz_presentation_text .quiz_presentation_description {
  margin-bottom: .75rem;
}

.quiz_carousel .quiz_carousel_box {
  width: 100%;
  margin-right: 10px;
}

.quiz_carousel .quiz_carousel_box .quiz_carousel_question {
  background-color: #fff;
  padding: 30px;
  text-align: center;
}

.quiz_carousel .quiz_carousel_box .quiz_carousel_answers {
  padding: 30px;
  background-color: #f7f7f7;
  text-align: center;
}

.quiz_carousel .quiz_carousel_box .quiz_carousel_answers .quiz_carousel_answers_box {
  display: inline-block;
  vertical-align: top;
  text-align: left;
}

.quiz_carousel .quiz_carousel_box .quiz_carousel_answers .quiz_carousel_answers_box .quiz_question_label_radio {
  overflow: hidden;
  position: relative;
  margin: .5rem 1rem;
}

.quiz_carousel .quiz_carousel_box .quiz_carousel_answers .quiz_carousel_answers_box .quiz_question_label_radio .circle {
  display: inline-block;
  vertical-align: middle;
  width: 22px;
  height: 22px;
  border: 2.5px solid #ddd;
  box-sizing: border-box;
  border-radius: 11px;
  margin-right: 3px;
  position: relative;
}

.quiz_carousel .quiz_carousel_box .quiz_carousel_answers .quiz_carousel_answers_box input[type=radio] {
  opacity: 0;
  z-index: -1;
  visibility: hidden;
  width: 1px;
  height: 1px;
  position: absolute;
  left: -1px;
  top: -1px;
}

.quiz_carousel .quiz_carousel_box .quiz_carousel_answers .quiz_carousel_answers_box input[type=radio]:checked + .quiz_question_label_radio .circle {
  border-color: #17a2b8;
}

.quiz_carousel .quiz_carousel_box .quiz_carousel_answers .quiz_carousel_answers_box input[type=radio]:checked + .quiz_question_label_radio .circle::after {
  content: '';
  position: absolute;
  top: 2.1px;
  left: 2.1px;
  width: 13px;
  height: 13px;
  border-radius: 7px;
  background-color: #17a2b8;
}

.quiz_carousel .quiz_carousel_box .quiz_carousel_answers .quiz_carousel_answers_box .quiz_question_label_check {
  overflow: hidden;
  position: relative;
  margin: .5rem 1rem;
}

.quiz_carousel .quiz_carousel_box .quiz_carousel_answers .quiz_carousel_answers_box .quiz_question_label_check .square {
  display: inline-block;
  vertical-align: middle;
  width: 22px;
  height: 22px;
  border: 2.5px solid #ddd;
  box-sizing: border-box;
  margin-right: .2rem;
  position: relative;
}

.quiz_carousel .quiz_carousel_box .quiz_carousel_answers .quiz_carousel_answers_box input[type=checkbox] {
  opacity: 0;
  z-index: -1;
  visibility: hidden;
  width: 1px;
  height: 1px;
  position: absolute;
  left: -1px;
  top: -1px;
}

.quiz_carousel .quiz_carousel_box .quiz_carousel_answers .quiz_carousel_answers_box input[type=checkbox]:checked + .quiz_question_label_check .square {
  border-color: #17a2b8;
}

.quiz_carousel .quiz_carousel_box .quiz_carousel_answers .quiz_carousel_answers_box input[type=checkbox]:checked + .quiz_question_label_check .square::after {
  content: '';
  position: absolute;
  top: 2.1px;
  left: 2.1px;
  width: 13px;
  height: 13px;
  background-color: #17a2b8;
}

.quiz_carousel .quiz_carousel_box .quiz_carousel_answers .quiz_answer_status {
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
  margin: 1rem 0;
}

.quiz_carousel .quiz_carousel_box .quiz_carousel_answers .quiz_answer_status.ok {
  color: #06d6a0;
}

.quiz_carousel .quiz_carousel_box .quiz_carousel_answers .quiz_answer_status.error {
  color: #e9724c;
}

.quiz_carousel .quiz_carousel_box .quiz_carousel_answers .quiz_carousel_pill {
  box-shadow: 0 0 7px rgba(0, 155, 154, 0.1);
  padding: 1rem 2rem;
  max-width: 600px;
  box-sizing: border-box;
  margin: 1rem auto;
  background-color: #fff;
  text-align: center;
}

.quiz_carousel .quiz_carousel_box .quiz_carousel_answers .quiz_carousel_pill.ok {
  background-color: #d6f5d6;
}

.quiz_carousel .quiz_carousel_box .quiz_carousel_answers .quiz_carousel_pill.error {
  background-color: #f9dcd2;
}

.quiz_carousel .quiz_carousel_box .quiz_carousel_answers .quiz_carousel_pill .quiz_carousel_pill_title {
  color: #17a2b8;
  font-size: 1.3rem;
  font-weight: 700;
  margin-bottom: .5rem;
}

.quiz_carousel .quiz_carousel_box .quiz_carousel_answers .quiz_question_continue {
  margin-top: 1.5rem;
  padding: .75rem 1.5rem;
  background-color: #007bff;
  color: #fff;
  font-weight: 700;
}

.quiz_carousel .quiz_carousel_box .quiz_carousel_answers .quiz_question_continue:hover {
  background-color: #007bff;
}

.quiz_carousel .quiz_carousel_box .quiz_carousel_answers.results {
  background-color: #007bff;
  padding: 45px 30px;
}

.quiz_carousel .quiz_carousel_box .quiz_carousel_answers.results h4 {
  color: #fff;
}

/* Gritter */
/* the norm */
#gritter-notice-wrapper {
  position: fixed;
  top: 20px;
  right: 20px;
  width: 301px;
  z-index: 9999;
}

#gritter-notice-wrapper.top-left {
  left: 20px;
  right: auto;
}

#gritter-notice-wrapper.bottom-right {
  top: auto;
  left: auto;
  bottom: 20px;
  right: 20px;
}

#gritter-notice-wrapper.bottom-left {
  top: auto;
  right: auto;
  bottom: 20px;
  left: 20px;
}

.gritter-item-wrapper {
  position: relative;
  margin: 0 0 10px 0;
  background: url("../images/ie-spacer.gif");
  /* ie7/8 fix */
}

.gritter-top {
  background: url(../images/gritter.png) no-repeat left -30px;
  height: 10px;
}

.hover .gritter-top {
  background-position: right -30px;
}

.gritter-bottom {
  background: url(../images/gritter.png) no-repeat left bottom;
  height: 8px;
  margin: 0;
}

.hover .gritter-bottom {
  background-position: bottom right;
}

.gritter-item {
  display: block;
  background: url(../images/gritter.png) no-repeat left -40px;
  color: #eee;
  padding: 2px 11px 8px 11px;
  font-size: 11px;
  font-family: verdana;
}

.hover .gritter-item {
  background-position: right -40px;
}

.gritter-item p {
  padding: 0;
  margin: 0;
  word-wrap: break-word;
}

.gritter-close {
  display: none;
  position: absolute;
  top: 5px;
  left: 3px;
  background: url(../images/gritter.png) no-repeat left top;
  cursor: pointer;
  width: 30px;
  height: 30px;
  text-indent: -9999em;
}

.gritter-title {
  font-size: 14px;
  font-weight: bold;
  padding: 0 0 7px 0;
  display: block;
  text-shadow: 1px 1px 0 #000;
  /* Not supported by IE :( */
}

.gritter-image {
  width: 48px;
  height: 48px;
  float: left;
}

.gritter-with-image,
.gritter-without-image {
  padding: 0;
}

.gritter-with-image {
  width: 220px;
  float: right;
}

/* for the light (white) version of the gritter notice */
.gritter-light .gritter-item,
.gritter-light .gritter-bottom,
.gritter-light .gritter-top,
.gritter-light .gritter-close {
  background-image: url(../images/gritter-light.png);
  color: #222;
}

.gritter-light .gritter-title {
  text-shadow: none;
}

.gritter-item-wrapper {
  background-image: none !important;
  box-shadow: 0 2px 10px rgba(50, 50, 50, 0.5);
  background: rgba(50, 50, 50, 0.92);
}

.gritter-item-wrapper.gritter-info {
  background: rgba(49, 81, 133, 0.92);
}

.gritter-item-wrapper.gritter-error {
  background: rgba(153, 40, 18, 0.92);
}

.gritter-item-wrapper.gritter-success {
  background: rgba(89, 131, 75, 0.92);
}

.gritter-item-wrapper.gritter-warning {
  background: rgba(190, 112, 31, 0.92);
}

.gritter-item-wrapper.gritter-light {
  background: rgba(245, 245, 245, 0.95);
  border: 1px solid #BBB;
}

.gritter-item-wrapper.gritter-light.gritter-info {
  background: rgba(232, 242, 255, 0.95);
}

.gritter-item-wrapper.gritter-light.gritter-info .gritter-item {
  color: #4A577D;
}

.gritter-item-wrapper.gritter-light.gritter-error {
  background: rgba(255, 235, 235, 0.95);
}

.gritter-item-wrapper.gritter-light.gritter-error .gritter-item {
  color: #894A38;
}

.gritter-item-wrapper.gritter-light.gritter-success {
  background: rgba(239, 250, 227, 0.95);
}

.gritter-item-wrapper.gritter-light.gritter-success .gritter-item {
  color: #416131;
}

.gritter-item-wrapper.gritter-light.gritter-warning {
  background: rgba(252, 248, 227, 0.95);
}

.gritter-bottom, .gritter-close, .gritter-item, .gritter-top {
  background-image: none;
}

.gritter-item-wrapper.gritter-light.gritter-warning .gritter-item {
  color: #946446;
}

.gritter-item p {
  line-height: 1.8;
}

.gritter-close {
  left: auto;
  right: 4px;
  width: 18px;
  height: 18px;
  line-height: 17px;
  text-align: center;
  border: 2px solid transparent;
  border-radius: 16px;
  color: #E17B67;
  font-size: 0;
  text-indent: 0;
}

.gritter-close:before {
  font-family: FontAwesome;
  font-size: 16px;
  content: "\f00d";
}

.gritter-info .gritter-close {
  color: orange;
}

.gritter-error .gritter-close, .gritter-success .gritter-close, .gritter-warning .gritter-close {
  color: #FFEA07;
}

.gritter-close:hover {
  color: #FFF !important;
  text-decoration: none;
}

.gritter-light .gritter-bottom, .gritter-light .gritter-close, .gritter-light .gritter-item, .gritter-light .gritter-top {
  background-image: none;
  color: #444;
}

.gritter-light .gritter-close:hover {
  color: #8A3104 !important;
}

.gritter-center {
  position: fixed;
  left: 33%;
  right: 33%;
  top: 33%;
}

.wysiwyg-style1 .btn-group, .wysiwyg-style2 .btn-group, .wysiwyg-toolbar {
  position: relative;
}

@media only screen and (max-width: 767px) {
  .gritter-center {
    left: 16%;
    right: 16%;
    top: 30%;
  }
}

@media only screen and (max-width: 540px) {
  .quiz_carousel .quiz_carousel_box .quiz_carousel_question {
    padding: 30px 15px 15px 15px;
  }
  .quiz_carousel .quiz_carousel_box .quiz_carousel_answers {
    padding: 30px 15px;
  }
  .quiz_carousel .quiz_carousel_box .quiz_carousel_answers .quiz_carousel_answers_box {
    font-size: 1.1rem;
  }
}

@media only screen and (max-width: 480px) {
  .gritter-center {
    left: 30px;
    right: 30px;
  }
}

@media only screen and (max-width: 320px) {
  .gritter-center {
    left: 10px;
    right: 10px;
  }
}
